import {size, space, layout, typography, opacity} from "styled-system";
import styled, { keyframes } from "styled-components";

export const zipLine = (start, end) => keyframes`
  from {
    width: ${start};
  }

  to {
    width: ${end};
  }
`;

const ColorLine = styled.div`
 ${size}
 ${space}
 ${typography}
 ${layout}
 ${opacity}
  background: linear-gradient(
    90deg,
    rgb(64, 132, 186),
    rgb(149, 71, 155),
    rgb(190, 60, 78),
    rgb(246, 137, 88),
    rgb(250, 187, 27),
    rgb(255, 255, 255)
  );
  filter: blur(1px);
  height: 4px;
  position: ${props => props.position};
  top: ${props => props.top};
  left: ${props => props.left};
  float: ${props => props.float};
  /* width: ${props => props.width}; */

  animation: ${props => props.animation} ${props => props.animationProps};
`;

export default ColorLine;
