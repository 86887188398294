import React from "react";
import QueueAnim from "rc-queue-anim";
import styled, { keyframes } from "styled-components";
import { Box } from "reflexbox/styled-components";
import images from "./images";

const laptopOpacity = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1
    }
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  ${props => props.theme.mq.md} {
    height: 70%;
    width: 70%;
  }
`;
const LaptopBox = styled(Box)`
  position: absolute;
  right: -100px;
  top: ${props => props.theme.space[7] + 50}px;
  ${props => props.theme.mq.md} {
    right: -100px;
    top: 98px;
  }
  z-index: 20;
  animation: ${laptopOpacity} 1s ease-in-out forwards;
`;
const PhoneBox = styled(Box)`
  position: absolute;
  right: 306px;
  top: ${props => props.theme.space[7] + 240}px;
  ${props => props.theme.mq.md} {
    right: 286px;
    top: 228px;
  }
      ${props => props.theme.mq.sm} {
    right: 238px;
    top: 228px;
    }
`;

const TabletBox = styled(Box)`
  position: absolute;
  right: 250px;
  top: ${props => props.theme.space[7] + 50}px;
  z-index: 15;
  ${props => props.theme.mq.md} {
    right: 200px;
    top: 108px;
  }
        ${props => props.theme.mq.sm} {
    right: 164px;
    top: 108px;
    }
`;
const Part1Box = styled(Box)`
  position: absolute;
  right: 250px;
  top: ${props => props.theme.space[7] + 287}px;
  right: 120px;
  ${props => props.theme.mq.md} {
    top: 265px;
    right: 190px;
  }
    ${props => props.theme.mq.sm} {
        top: 265px;
    right: 118px;

    }
  z-index: 21;
`;
const Part2Box = styled(Box)`
  position: absolute;
  right: 250px;
  top: ${props => props.theme.space[7] + 240}px;
  right: 220px;
  z-index: 21;
  ${props => props.theme.mq.md} {
    top: 238px;
    right: 250px;
  }
      ${props => props.theme.mq.sm} {
        top: 238px;
    right: 182px;
    }
`;
const Part3Box = styled(Box)`
  position: absolute;
  right: 250px;
  top: ${props => props.theme.space[7] + 132}px;
  right: 250px;
  z-index: 21;
  ${props => props.theme.mq.md} {
    top: 160px;
    right: 300px;
  }
      ${props => props.theme.mq.sm} {
        top: 157px;
    right: 218px;
    }
`;
const Part4Box = styled(Box)`
  position: absolute;
  right: 250px;
  top: ${props => props.theme.space[7] + 240}px;
  right: 40px;
  z-index: 21;
  ${props => props.theme.mq.md} {
    top: 248px;
    right: 130px;
  }
      ${props => props.theme.mq.sm} {
    top: 244px;
    right: 60px;
    }
`;

const BoxContainer = styled(Box)`
  position: relative;
  position: relative;
  top: -430px;
  right: -80px;
      transform: scale(1.13);
    margin-left: 1200px;
    margin-top: -45px;
  ${props => props.theme.mq.md} {
    top: -330px;
    right: -180px;
  }
    ${props => props.theme.mq.lgl} {
      transform: scale(1);
        margin-left: 0;
            margin-top: 0;
  }
  
  ${props => props.theme.mq.sm} {
    width: 500px;
    height: 400px;
    top: 0;
    position: absolute;
    text-align: center;
    left: 50%;
    margin-left: -250px;
    top: 80vh;
    margin-top: -400px;
  }
`;
const HomeImage = () => {
  return (
    <BoxContainer>
      <QueueAnim delay={850} duration={500} type="bottom">
        <LaptopBox key="laptop" height={["400px"]} width={["568px"]}>
          <Image src={images.laptop} srcSet={images.laptop2x}></Image>
        </LaptopBox>
        <TabletBox key="tablet" height={["200px"]} width={["368px"]}>
          <Image src={images.tablet} srcSet={images.tablet2x}></Image>
        </TabletBox>
        <PhoneBox key="phone" height={["140px"]} width={["261px"]}>
          <Image src={images.phone} srcSet={images.phone2x}></Image>
        </PhoneBox>
      </QueueAnim>
      <QueueAnim delay={1350} interval={100} duration={600} type="right">
        <Part1Box key="part1" height={["100px"]} width={["120px"]}>
          <Image src={images.part3} srcSet={images.part32x}></Image>
        </Part1Box>
        <Part2Box key="part2" height={["70px"]} width={["127px"]}>
          <Image src={images.part2} srcSet={images.part22x}></Image>
        </Part2Box>
        <Part3Box key="part3" height={["50px"]} width={["50px"]}>
          <Image src={images.part2} srcSet={images.part12x}></Image>
        </Part3Box>
        <Part4Box key="part4" height={["80px"]} width={["112px"]}>
          <Image src={images.part4} srcSet={images.part42x}></Image>
        </Part4Box>
      </QueueAnim>
    </BoxContainer>
  );
};

export default HomeImage;
