import React from "react";
import OverPack from "../common/scroll/OverPackScroll";
import QueueAnim from "rc-queue-anim";
import { Flex, Box } from "reflexbox/styled-components";
import styled from "styled-components";
import Heading, { MediumTexty, LightTexty } from "../common/text/Heading";
import Screen from "../screen/Screen";
import ColorLine, { zipLine } from "../common/color-line/ColorLine";
import DescriptionBox from "../common/text/Description";
import OurStoryImage from "./OurStoryImage";
import { useBigScreen, useMobile } from "../common/responsive";
import { sectionsText } from "../common/text/sectionTexts";

const Text = sectionsText.Hero;

const palleteItems = [
  {
    backgroundColor: "blue",
    text: "enterprise software",
    before: true,
  },
  {
    backgroundColor: "purple",
    text: "tv apps",
  },
  {
    backgroundColor: "red",
    text: "mobile apps",
  },
  {
    backgroundColor: "orange",
    text: "infrastructure solutions",
  },
  {
    backgroundColor: "yellow",
    text: "software platforms",
    after: true,
  },
];

const PalleteBox = styled(Flex)`
  filter: drop-shadow(
    1px 1px 5px ${(props) => props.theme.colors[props.backgroundColor]}
  );

  text-transform: uppercase;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: skewX(-15deg) !important;
  ${(props) => props.theme.mq.xs} {
    transform: skewX(0deg) !important;
  }
  ${(props) => !props.before} &&::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: -100%;
    background: ${(props) => props.theme.colors[props.backgroundColor]};
    z-index: -1;
  }
  ${(props) => !props.after} &&::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 100%;
    background: ${(props) => props.theme.colors[props.backgroundColor]};
    z-index: -1;
  }
`;
const PalleteTextBox = styled(Box)`
  transform: skewX(15deg);
  ${(props) => props.theme.mq.xs} {
    transform: skewX(0deg) !important;
  }
`;
const Pallete = React.forwardRef((props, _ref) => {
  return (
    <QueueAnim
      ease="easeInOutSine"
      delay={450}
      component={Flex}
      width={1}
      mt={4}
      flexWrap="wrap"
      {...props}
    >
      {palleteItems.map(({ text, ...rest }, index) => (
        <PalleteBox
          height={["70px", "100px"]}
          width={[1, 1 / 5]}
          key={index}
          {...rest}
        >
          {text.split(" ").map((part, index) => (
            <PalleteTextBox fontSize={[3, 2, 3]} key={index}>
              {part}
            </PalleteTextBox>
          ))}
        </PalleteBox>
      ))}
    </QueueAnim>
  );
});

const OurStory = () => (
  <Screen marginTop={"-15vh"} paddingTop={"10vh"}>
    <OverPack id="our-story" playScale={0.3} always={false}>
      <QueueAnim
        component={Flex}
        height="100%"
        width="100%"
        justifyContent="center"
        flexDirection="column"
        key="queue"
        type="bottom"
      >
        <Heading
          key="heading"
          px={5}
          textAlign={`${useMobile() ? "center" : "left"}`}
        >
          <LightTexty duration={50} inline>
            A
          </LightTexty>{" "}
          <MediumTexty duration={200} delay={50} inline>
            hero
          </MediumTexty>{" "}
          <LightTexty delay={200} inline>
            story
          </LightTexty>
        </Heading>
        <ColorLine
          key="color-line"
          width="100%"
          my={4}
          left={useBigScreen() ? "calc(-50vw + 50%)" : ""}
          animation={zipLine("0", `${useBigScreen() ? "100vw" : "100%"}`)}
          animationProps="1s ease-in-out forwards"
        ></ColorLine>
        <DescriptionBox
          key="description"
          pl={[2, 4, 5]}
          pr={[2, 4, 0]}
          width={[1, 1, 1 / 2]}
          mb={4}
          fontFamily="text"
        >
          {Text}
        </DescriptionBox>
        <OurStoryImage></OurStoryImage>
        <Pallete key="pallete"></Pallete>
      </QueueAnim>
    </OverPack>
  </Screen>
);

export default React.memo(OurStory);
