import React from "react";
import styled from "styled-components";
import { Box } from "reflexbox/styled-components";
import {useMobile} from "../responsive";

const TextBox = styled(Box)`
  text-transform: ${props => props.textTransform};
`;
const DescriptionBox = React.forwardRef((props, _ref) => (
  <TextBox color="text" fontSize={3} {...props} paddingLeft={`${useMobile() ? "32px !important" : ""}`}  paddingRight={`${useMobile() ? "32px !important" : ""}`}></TextBox>
));

export default DescriptionBox;
