import React from "react";
import { OverPack } from "rc-scroll-anim";

const OverPackScroll = props => {
  return (
    <OverPack playScale={0.3} always={false} {...props}>
      {props.children}
    </OverPack>
  );
};

export default OverPackScroll;
