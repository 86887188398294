import React from "react";
import styled from "styled-components";
import { Box, Flex } from "reflexbox/styled-components";

const StyledBox = styled(Box)`
  background: ${props =>
    props.isOpen ? "transparent" : props.theme.colors.white};
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 30px;

  &::before {
    top: ${props => (props.isOpen ? 0 : -7)}px;
    transform: ${props => (props.isOpen ? "rotate(45deg)" : "rotate(0deg)")};
  }
  &::after {
    top: ${props => (props.isOpen ? 0 : 7)}px;
    transform: ${props => (props.isOpen ? "rotate(-45deg)" : "rotate(0deg)")};
  }

  &::after,
  &::before {
    background: #fff;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }
`;

const HamburgerBtn = ({ isOpen, toggleMenu, ...props }) => {
  const handleClick = () => {
    toggleMenu();
  };

  return (
    <Flex
      sx={{
        cursor: "pointer"
      }}
      onClick={handleClick}
      py={[3]}
      alignItems="center"
    >
      <StyledBox isOpen={isOpen} {...props}></StyledBox>
    </Flex>
  );
};

export default HamburgerBtn;
