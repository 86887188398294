import React from "react";
import HamburgerBtn from "./HamburgerBtn";
import HamburgerItems from "./HamburgerItems";

const HamburgerMenu = ({ isOpen, toggleMenu, closeMenu }) => {
  return (
    <>
      <HamburgerBtn isOpen={isOpen} toggleMenu={toggleMenu}></HamburgerBtn>
      <HamburgerItems isOpen={isOpen} close={closeMenu}></HamburgerItems>
    </>
  );
};

export default HamburgerMenu;
