import React, { useRef, useEffect } from "react";
import OverPack from "../common/scroll/OverPackScroll";
import QueueAnim from "rc-queue-anim";
import { Flex, Box } from "reflexbox/styled-components";
import styled from "styled-components";
import Heading, { MediumTexty, LightTexty } from "../common/text/Heading";
import ColorLine, { zipLine } from "../common/color-line/ColorLine";
import Screen from "../screen/Screen";
import DescriptionBox from "../common/text/Description";
import Technologies from "./Technologies";
import SoftwareImage from "./SoftwareImage";
import { useBigScreen, useMobile } from "../common/responsive";
import { sectionsText } from "../common/text/sectionTexts";

const Text = sectionsText.Software;
const QueueAnimWithStyle = styled(QueueAnim)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const Software = () => {
  return (
    <Screen>
      <OverPack id="software" playScale={0.3} always={false}>
        <QueueAnimWithStyle key="queue" type="bottom">
          <Heading
            key="heading"
            px={5}
            textAlign={`${useMobile() ? "center" : "left"}`}
          >
            <MediumTexty duration={250} inline>
              Software
            </MediumTexty>{" "}
            <LightTexty inline delay={250}>
              Development
            </LightTexty>
          </Heading>
          <Flex key="color-line">
            <Box width={[1, 1, "60%"]}>
              <ColorLine
                key="color-line"
                my={4}
                float="right"
                animation={zipLine("0", `${useBigScreen() ? "60vw" : "100%"}`)}
                animationProps="1s ease-in-out forwards"
              ></ColorLine>
            </Box>
          </Flex>
          <Flex flexWrap="wrap" minHeight={250} key="description">
            <DescriptionBox
              pl={[2, 4, 5]}
              pr={[2, 4, 0]}
              width={[1, 1, "53%", "55%"]}
              fontFamily="text"
            >
              {Text}
            </DescriptionBox>
            <Box
              px={4}
              width={[1, 1, "47%", "45%"]}
              height={[300, 300, "auto"]}
              pt={[5, 5, 0]}
              sx={{ position: "relative" }}
            >
              <SoftwareImage></SoftwareImage>
            </Box>
          </Flex>
          <Technologies key="skills"></Technologies>
        </QueueAnimWithStyle>
      </OverPack>
    </Screen>
  );
};

export default React.memo(Software);
