import { useMediaQuery } from "react-responsive";
import breakpoints from "./breakpoints";

export const useDesktopOrLaptop = () =>
  useMediaQuery({
    query: `(min-width: ${breakpoints.md})`
  });

export const useBigScreen = () =>
  useMediaQuery({ query: `(min-width: ${breakpoints.xl})` });

export const useTablet = () =>
  useMediaQuery({
    query: `(max-width: ${Number.parseInt(breakpoints.md) - 1}px)`
  });

export const useMobile = () =>
  useMediaQuery({
    query: `(max-width: ${Number.parseInt(breakpoints.sm) - 1}px)`
  });
export const useSmallScreen = () =>
  useMediaQuery({
    query: `(max-width: ${Number.parseInt(breakpoints.xs) - 1}px)`
  });
export const usePortrait = () =>
  useMediaQuery({ query: "(orientation: portrait)" });

export const useRetina = () =>
  useMediaQuery({ query: "(min-resolution: 2dppx)" });

export const useMaxBigScreen = () =>
  useMediaQuery({
    query: `(max-width: ${Number.parseInt(breakpoints.xl) - 1}px)`
  });
