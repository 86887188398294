import React from "react";
import { Flex, Box } from "reflexbox/styled-components";
import QueueAnim from "rc-queue-anim";
import { Link } from "rc-scroll-anim";
import styled, { keyframes } from "styled-components";
import circleImg from "../common/images/circle-number.png";
import DescriptionBox from "../common/text/Description";
import { useSmallScreen } from "../common/responsive";

const rotate = degree => keyframes`
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(${degree}deg);
    }
`;

const maxDegree = 500;
const skillsData = [
  {
    number: 17,
    text: ["engineers"],
    degree: Math.random() * maxDegree
  },
  {
    number: 25,
    text: ["satisfied", "customers"],
    degree: Math.random() * maxDegree
  },
  {
    number: 42,
    text: ["applications", "built"],
    degree: Math.random() * maxDegree
  },
  {
    number: 54,
    text: ["projects", "completed"],
    degree: Math.random() * maxDegree
  },
  {
    number: 112,
    text: ["years of", "experience"],
    degree: Math.random() * maxDegree
  }
];

const OuterSkillBox = styled(Box)`
  animation: ${props => rotate(props.degree)} 450ms linear forwards;
`;

const SkillBox = styled(Box)`
  && {
    position: relative;
    background-image: url(${circleImg});
    cursor: ${props => (props.type === "link" ? "pointer" : "")};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 2000ms ease-out;
  }
  
   
  
  &&>div{
  transform: rotate(${props => -props.degree}deg);
      transition: all 2000ms ease-out;
  }

  &&:hover {
    transform: ${props => props.type !== "link"} scale(1.2) rotate(950deg);
  }
    &&:hover>div {
    transform: ${props => props.type !== "link"} rotate(${props => -props.degree-950}deg);
  }
`;

const NumberBox = styled(Box)`
  
`;

const Skills = React.forwardRef((props, _ref) => {
  return (
    <QueueAnim
      delay={450}
      flexWrap="wrap"
      component={Flex}
      width={1}
      mt={4}
      pt={[6, 0]}
      {...props}
    >
      <Box key="before" width={[1 / 2, 0, 2 / 9]}></Box>
      {skillsData.map(({ text, number, src, type, to, ...rest }, index) => (
        <Link
          component={Flex}
          key={index}
          to={to}
          offsetTop={90}
          width={[1 / 2, 1 / 5, 1 / 9]}
          sx={{
            position: "relative",
            left: useSmallScreen() && index === 0 ? "-25%" : "",
            cursor: type === "link" && "pointer"
          }}
          flexDirection="column"
        >
          <OuterSkillBox
              {...rest}
          >
          <SkillBox
            height={[170, 200]}
            fontWeight="bold"
            color="white"
            type={type}
            {...rest}
          >
            <NumberBox fontSize={[6]} degree={rest.degree}>
              {number}
            </NumberBox>
          </SkillBox>
          </OuterSkillBox>

          <Box mt={[-27, -40]}>
            {text.map((txt, index) => (
              <DescriptionBox
                key={index}
                textAlign="center"
                textTransform="uppercase"
                color="white"
                fontWeight="medium"
                fontSize={[2]}
              >
                {txt}
              </DescriptionBox>
            ))}
          </Box>
        </Link>
      ))}
      <Box key="after" width={[1 / 2, 0, 2 / 9]}></Box>
    </QueueAnim>
  );
});

export default Skills;
