import React from "react";
import QueueAnim from "rc-queue-anim";
import styled, { keyframes, css } from "styled-components";
import { Box } from "reflexbox/styled-components";
import images from "./images";

const neuronetOpacity = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1
    }
`;
const circleRotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
`;

const imgCss = css`
  height: 100%;
  width: 100%;
  ${props => props.theme.mq.md} {
    height: 90%;
    width: 90%;
  }
`;
const Image = styled.img`
  ${imgCss}
`;
const CircularImage = styled.img`
    ${imgCss}
    animation: ${circleRotate} 4s ease-in-out infinite ${props =>
  props.reverse ? "reverse" : ""};
`;

const NeuronetBox = styled(Box)`
  position: absolute;
  z-index: 20;
  top: -150px;
  right: -70px;
  animation: ${neuronetOpacity} 1s ease-in-out forwards;
`;

const Circle1Box = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 231px;
  top: 293px;
  ${props => props.theme.mq.md} {
    right: 271px;
    top: 250px;
  }
`;
const Circle2Box = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 511px;
  top: 149px;
  ${props => props.theme.mq.md} {
    right: 518px;
    top: 120px;
  }
`;
const Circle3Box = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 338px;
  top: 99px;
  ${props => props.theme.mq.md} {
    right: 364px;
    top: 74px;
  }
`;
const Circle4Box = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 106px;
  top: -15px;
  ${props => props.theme.mq.md} {
    right: 155px;
    top: -30px;
  }
`;
const Circle5Box = styled(Box)`
  position: absolute;
  z-index: 20;
  top: 129px;
  right: 164px;
  ${props => props.theme.mq.md} {
    top: 101px;
    right: 207px;
  }
`;
const Circle6Box = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 251px;
  top: 1px;
  ${props => props.theme.mq.md} {
    right: 286px;
    top: -13px;
  }
`;
const BoxContainer = styled(Box)`
  position: relative;

  ${props => props.theme.mq.md} {
    right: -120px;
  }
  ${props => props.theme.mq.sm} {
    position: absolute;
    top: -300px;
    right: auto;
    left: 50%;
    transform: translateX(380px);
    filter: opacity(0.2);
    z-index: -1;
  }
`;

const ContactImage = () => {
  return (
    <BoxContainer>
      <QueueAnim delay={1200} duration={650} type="bottom">
        <NeuronetBox key="neuronet" width={800} height={673}>
          <Image src={images.neuronet} srcSet={images.neuronet2x}></Image>
        </NeuronetBox>
        <Circle1Box key="circle1" width={130} height={130}>
          <CircularImage
            src={images.circle1}
            srcSet={images.circle12x}
          ></CircularImage>
        </Circle1Box>
        <Circle2Box key="circle2" width={130} height={130}>
          <CircularImage
            reverse={true}
            src={images.circle2}
            srcSet={images.circle22x}
          ></CircularImage>
        </Circle2Box>
        <Circle3Box key="circle3" width={130} height={130}>
          <Image src={images.circle3} srcSet={images.circle32x}></Image>
        </Circle3Box>
        <Circle4Box key="circle4" width={130} height={130}>
          <CircularImage
            src={images.circle4}
            srcSet={images.circle42x}
          ></CircularImage>
        </Circle4Box>
        <Circle5Box key="circle5" width={130} height={130}>
          <Image src={images.circle5} srcSet={images.circle52x}></Image>
        </Circle5Box>
        <Circle6Box key="circle6" width={130} height={130}>
          <Image src={images.circle6} srcSet={images.circle62x}></Image>
        </Circle6Box>
      </QueueAnim>
    </BoxContainer>
  );
};

export default ContactImage;
