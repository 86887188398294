import React from "react";
import QueueAnim from "rc-queue-anim";
import styled, { keyframes } from "styled-components";
import { Box } from "reflexbox/styled-components";
import images from "./images";

const elements1Opacity = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1
    }
`;
const circleRotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  ${props => props.theme.mq.md} {
    height: 90%;
    width: 90%;
  }
  ${props => !props.anim} animation: ${circleRotate} 9s linear infinite;
`;

const Elements1Box = styled(Box)`
  position: absolute;
  right: -56px;
  z-index: 20;
  animation: ${elements1Opacity} 1s ease-in-out forwards;
`;
const Elements2Box = styled(Box)`
  position: absolute;
  right: -52px;
  top: 10px;
  z-index: 20;
`;

const CircleBox = styled(Box)`
  position: absolute;
  right: 78px;
  z-index: 20;
  top: 115px;

  ${props => props.theme.mq.md} {
    right: 93px;
    top: 104px;
  }
`;
const CoffecupBox = styled(Box)`
  position: absolute;
  right: 122px;
  z-index: 21;
  top: 33px;
  ${props => props.theme.mq.md} {
    right: 134px;
    top: 30px;
  }
`;

const MemoryCardBox = styled(Box)`
  position: absolute;
  right: 234px;
  z-index: 20;
  top: 134px;
  ${props => props.theme.mq.md} {
    right: 238px;
    z-index: 20;
    top: 123px;
  }
`;
const PliersBox = styled(Box)`
  position: absolute;

  z-index: 20;
  top: 227px;
  right: 75px;
  ${props => props.theme.mq.md} {
    top: 207px;
    right: 94px;
  }
`;
const PenBox = styled(Box)`
  position: absolute;
  right: -12px;
  z-index: 20;
  top: 134px;
  ${props => props.theme.mq.md} {
    right: 14px;
    top: 119px;
  }
`;

const HeadBox = styled(Box)`
  position: absolute;
  right: 28px;
  z-index: 20;
  top: 64px;
  ${props => props.theme.mq.md} {
    right: 37px;
    top: 59px;
  }
`;

const BoxContainer = styled(Box)`
  position: absolute;
  left: 320px;
  top: -60px;
  ${props => props.theme.mq.xl} {
    top: -30px;
  }

  ${props => props.theme.mq.md} {
    position: relative;
    left: -30px;
    top: 30px;
  }
  ${props => props.theme.mq.sm} {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(125px);
  }
`;

const ConsyltancyImage = () => {
  return (
    <BoxContainer>
      <QueueAnim delay={1200} duration={650} type="bottom">
        <Elements1Box key="elems1" width={350} height={297}>
          <Image src={images.elements1} srcSet={images.elements12x}></Image>
        </Elements1Box>
        <Elements2Box key="elems2" width={350} height={330}>
          <Image src={images.elements2} srcSet={images.elements22x}></Image>
        </Elements2Box>

        <CoffecupBox key="coffe" width={40} height={55}>
          <Image src={images.coffecup} srcSet={images.coffecup2x}></Image>
        </CoffecupBox>
        <MemoryCardBox key="memory" width={30} height={37}>
          <Image src={images.memorycard} srcSet={images.memorycard}></Image>
        </MemoryCardBox>
        <PliersBox key="pliers" width={30} height={52}>
          <Image src={images.pliers} srcSet={images.pliers2x}></Image>
        </PliersBox>
        <PenBox key="pen" width={60} height={10}>
          <Image src={images.pen} srcSet={images.pen2x}></Image>
        </PenBox>
        <HeadBox key="head" width={190} height={190}>
          <Image src={images.head} srcSet={images.head2x}></Image>
        </HeadBox>
        <CircleBox key="circle" width={90} height={90}>
          <Image
            anim={true}
            src={images.circle}
            srcSet={images.circle2x}
          ></Image>
        </CircleBox>
      </QueueAnim>
    </BoxContainer>
  );
};

export default ConsyltancyImage;
