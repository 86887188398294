import React from "react";
import { Box } from "reflexbox/styled-components";
import styled from "styled-components";
import Texty from "rc-texty";

export const LightTexty = styled(Texty)`
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.headingLight};
  display: ${props => (props.inline ? "inline-block" : "block")};
`;
export const MediumTexty = styled(Texty)`
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.headingMedium};
  display: ${props => (props.inline ? "inline-block" : "block")};
`;

const TextBox = styled(Box)`
  text-transform: uppercase;
`;
const Heading = React.forwardRef((props, _ref) => (
  <TextBox
    letterSpacing={[3, 3, 5]}
    color="white"
    fontSize={[37, 40]}
    {...props}
  >
    {props.children}
  </TextBox>
));

export default Heading;
