import images from "./teams.images";
import cedomirS from "../common/images/teams/members/cedomir.jpg";
export default [
  {
    name: "Vanja Živković",
    role: "Office Manager",
    img: images.vanjaK,
    link: "https://www.linkedin.com/in/vanja-kukolj-0374211a4/",
  },
  {
    name: "Slaven Živković",
    role: "Senior Systems Engineer",
    img: images.slavenZ,
    link: "https://www.linkedin.com/in/slaven-zivkovic/",
  },
  {
    name: "Đorđe Turjačanin",
    role: "Deputy of Head of Development",
    img: images.djordjeT,
    link: "https://www.linkedin.com/in/djordje-turjacanin/",
    position: "0",
  },
  {
    name: "Aleksandar Buzaković",
    role: "Presales Systems Architect",
    img: images.aleksandarB,
    link: "https://www.linkedin.com/in/aleksandar-buzakovic/",
  },
  {
    name: "Marko Kovljenić",
    role: "Systems Architect",
    img: images.markoK,
    link: "https://www.linkedin.com/in/marko-kovljenic/",
  },
  {
    name: "Dejan Vučković",
    role: "Head of Development",
    img: images.dejanV,
    link: "https://www.linkedin.com/in/dejan-vuckovic/",
    position: "0",
  },
  {
    name: "Krstivoj Plavšić",
    role: "Software Engineer",
    img: images.krstivojP,
    link: "https://www.linkedin.com/in/krstivoj-plavsic-30105717b/",
    position: "center",
  },
  {
    name: "Aleksandar Antunović",
    role: "CEO",
    img: images.aleksandarA,
    link: "https://www.linkedin.com/in/aleksandar-antunovic-a652802/",
  },
  {
    name: "Nemanja Koričić",
    role: "QA Engineer",
    img: images.nemanjaK,
    link: "https://www.linkedin.com/in/nemanja-koricic/",
  },
  {
    name: "Jovica Kesić",
    role: "Product Owner",
    img: images.jovicaK,
    link: "https://www.linkedin.com/in/jovica-kesic/",
  },
  {
    name: "Marko Malinović",
    role: "Software Engineer",
    img: images.markoM,
    link: "https://www.linkedin.com/in/markomalinovic/",
    position: "center",
  },
  {
    name: "Danijel Marković",
    role: "UI/UX Designer",
    img: images.danijelM,
    link: "https://www.linkedin.com/in/danijel-markovic-45a1441a6/",
  },
  {
    name: "Jovan Danilović",
    role: "Frontend Engineer",
    img: images.jovanD,
    link: "https://www.linkedin.com/in/jovan-danilovic/",
  },
  {
    name: "Duško Dimitrić",
    role: "Software Engineer",
    img: images.duskoD,
    link: "https://www.linkedin.com/in/dusko-dimitric/",
  },
  {
    name: "Dejan Gajić",
    role: "Software Engineer",
    img: images.dejanG,
    link: "https://www.linkedin.com/in/dejan-gajic/",
  },
  {
    name: "Mihailo Vasić",
    role: "Support Engineer",
    img: images.mikiV,
    link: "https://www.linkedin.com/in/mihailo-vasic/",
    position: "center"
  },
  {
    name: "Čedomir Šušnjar",
    role: "Junior Software Engineer",
    img: images.cedomirS,
    link: "https://www.linkedin.com/in/%C4%8Dedomir-%C5%A1u%C5%A1njar-1a94111b1/",
    position: "center"
  },
  {
    name: "Stefan Radovanović",
    role: "Junior QA Engineer",
    img: images.stefanR,
    link: "https://www.linkedin.com/in/stefan-radovanovi%C4%87-95a76120a/",
    position: "center"
  }
];
