import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  
    html {
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    body{
        overflow-x:hidden;
        width: 100%;
        background: linear-gradient(90deg, #333133, #001348);
        font-family: ${props => props.theme.fonts.body};
        
    }
   
    #root{
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
   
    }
`;

export default GlobalStyle;
