import React from "react";
import { Flex, Box } from "reflexbox/styled-components";
import QueueAnim from "rc-queue-anim";
import { size, space, layout } from "styled-system";
import reactImg from "../common/images/tech-logos/react.png";
import mysqlImg from "../common/images/tech-logos/mysql.png";
import springImg from "../common/images/tech-logos/spring.png";
import webixImg from "../common/images/tech-logos/webix.png";
import seleniumImg from "../common/images/tech-logos/selenium.png";
import nodejsImg from "../common/images/tech-logos/nodejs.png";
import varnishImg from "../common/images/tech-logos/varnish.png";
import techUnderImg from "../common/images/tech-under.png";
import styled from "styled-components";
import { useSmallScreen } from "../common/responsive";
const data = [
  {
    src: reactImg
  },
  {
    src: webixImg
  },
  {
    src: nodejsImg
  },
  {
    src: springImg
  },
  {
    src: varnishImg
  },
  {
    src: mysqlImg
  },
  {
    src: seleniumImg
  }
];

const UnderImgBox = styled(Box)`
  background: url(${techUnderImg});
  background-size: contain;
  background-repeat: repeat-x;
  background-position: center;
  height: 100px;
  position: relative;
  top: -50px;
`;

const TechImg = styled.img`
  ${space}
  ${size}
  ${layout}
`;
const Technologies = React.forwardRef((props, _ref) => {
  return (
    <QueueAnim
      ease="easeInOutSine"
      delay={450}
      component={Flex}
      width={1}
      mt={[9, 9, 6]}
      flexWrap="wrap"
      {...props}
      px={[6, 2, 0]}
    >
      <Box key={-1} width={[1 / 3, 0, 1 / 9]}></Box>
      {data.map(({ src }, index) => (
        <Flex
          key={index}
          width={[1 / 3, 1 / 7, 1 / 9]}
          height={["100%"]}
          alignItems="center"
          flexDirection="column"
        >
          <TechImg
            src={src}
            alt=""
            width={[80, "80%", "80%", 100]}
            height={[80, "80%", "80%", 100]}
            marginBottom={[4, 0, 0, 0]}
          ></TechImg>
        </Flex>
      ))}
      <Box key={10} width={[1 / 3, 0, 1 / 9]}></Box>
      <Box key={11} width={[0, 0, 1 / 9]}></Box>
      <UnderImgBox
        sx={{
          display: useSmallScreen() ? "none" : "block"
        }}
        alt=""
        key={12}
        width={[1, 1, 7 / 9]}
      ></UnderImgBox>
      <Box key={13} width={[0, 0, 1 / 9]}></Box>
    </QueueAnim>
  );
});

export default Technologies;
