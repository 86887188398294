import React, { useState, useContext } from "react";

export const AppContext = React.createContext({
  isTopLogoShowed: false,
  toggleTopMenu: () => {},
  showTopMenu: () => {},
  hideTopMenu: () => {},
  hamburgerMenuOpen: false,
  toggleHamburgerMenu: () => {},
  closeHamburgerMenu: () => {},
});
export const AppConsumer = AppContext.Consumer;
export const AppProvider = (props) => {
  const [isTopLogoShowed, setTopLogo] = useState(false);
  const [hamburgerMenuOpen, setHamburgerMenu] = useState(false);

  const toggleHamburgerMenu = () => {
    setHamburgerMenu(!hamburgerMenuOpen);
  };
  const closeHamburgerMenu = () => {
    setHamburgerMenu(false);
  };

  const toggleTopMenu = () => {
    setTopLogo((prevState) => !prevState);
  };
  const showTopMenu = () => {
    setTopLogo(true);
  };
  const hideTopMenu = () => {
    setTopLogo(false);
  };
  const state = {
    isTopLogoShowed,
    toggleTopMenu,
    showTopMenu,
    hideTopMenu,
    hamburgerMenuOpen,
    toggleHamburgerMenu,
    closeHamburgerMenu,
  };

  return (
    <AppContext.Provider value={state}>{props.children}</AppContext.Provider>
  );
};

export const useAppState = () => useContext(AppContext);
