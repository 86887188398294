import React from "react";
export const sectionsText = {
  Software: (
    <>
      Creating software for us is not just our daily job – it’s our passion. We
      think that each problem is solvable, and we enjoy when it takes a lot of
      creative energy and thinking process for a problem to be solved.<br></br>
      <br></br> We don’t think that adopting programming languages and
      particular technologies is vital, because we are engineers. Though we are
      very skilled in some of them, and we can achieve outstanding performance
      while we develop code in Java or React, we think positively of C# and
      Angular. Or even C++. When story behind a project is great, we will adapt:
      no matter the tech that needs to be used.<br></br>
      <br></br> Our people have developed many different software products, from
      highly polished mobile apps, to extremely complicated enterprise
      solutions, and found a way to overcome any obstacle they may face.
    </>
  ),
  Hero: (
    <>
      We at Antecna don’t consider ourselves heroes. But we are truly inspired
      by them. Gathering people with various experiences in our team, mentored
      by older, driven by younger, we aim to deliver outstanding bytes of code.
      <br></br>
      Making bulletproof software is our passion, but we know that applications
      can be puzzling. That’s why we are doing our best to demystify software we
      make. So feel free to become a part of our hero story.
    </>
  ),
  Consultancy: (
    <>
      Prometheus gave people a fire, hoping that it will inspire them to do
      great things. In many cases we have a similar job: inventing fire is
      difficult, but sometimes necessary. <br></br>
      <br></br> That’s why we are proud of years of professional experience and
      partnerships with global consulting and software companies which confirms
      our commitment to our customers. Our team of proficient and highly
      educated electrical engineers and a wide network of external consultants
      specialize in the implementation of software solutions and infrastructure
      projects in the fields of construction, energy, telecommunications and
      transportation.<br></br>
      <br></br> For the solutions that we offer and implement, the main goal is
      to improve, optimize and automate business processes, for the purpose of
      achieving greater efficiency and leveraging our clients' resources to
      achieve day-to-day business savings through the digital transformation.
    </>
  )
};
