import hero from "../common/images/our-story/1x/hero.png";
import fire from "../common/images/our-story/1x/fire.png";
import neuronet from "../common/images/our-story/1x/neuronet.png";

import hero2x from "../common/images/our-story/2x/hero@2x.png";
import fire2x from "../common/images/our-story/2x/fire@2x.png";
import neuronet2x from "../common/images/our-story/2x/neuronet@2x.png";

export default {
  hero,
  fire,
  neuronet,
  hero2x,
  fire2x,
  neuronet2x
};
