import React from "react";
import { size, space, layout } from "styled-system";
import OverPack from "../common/scroll/OverPackScroll";
import QueueAnim from "rc-queue-anim";
import { Flex, Box } from "reflexbox/styled-components";
import styled from "styled-components";
import Heading, { LightTexty, MediumTexty } from "../common/text/Heading";
import Screen from "../screen/Screen";

import ColorLine, { zipLine } from "../common/color-line/ColorLine";
import DescriptionBox from "../common/text/Description";
import logo from "../common/images/antecna-full-logo.svg";
import igImg from "../common/images/social-media/ig.svg";
import gitImg from "../common/images/social-media/git.svg";
import fbImg from "../common/images/social-media/fb.svg";
import lnImg from "../common/images/social-media/in.svg";
import ytImg from "../common/images/social-media/yt.svg";
import twImg from "../common/images/social-media/tw.svg";
import { useMobile, useBigScreen, useMaxBigScreen } from "../common/responsive";
import ContactImage from "./ContactImage";

const images = [
  { src: fbImg, link: "https://www.facebook.com/antecna" },
  { src: igImg, link: "https://www.instagram.com/antecna/" },
  { src: lnImg, link: "https://www.linkedin.com/company/antecna" },
  { src: twImg, link: "https://twitter.com/antecna" },
  {
    src: ytImg,
    link: "https://www.youtube.com/channel/UC5iPQhc4LW-j0ZOyw7_4AFA/",
  },
  { src: gitImg, link: "https://github.com/antecna" },
];
const QueueAnimWithStyle = styled(QueueAnim)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
const LinkContact = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.text};
`;
const SocialImg = styled.img`
  ${size};
  ${space};
  ${layout};
  padding-right: 2px;
  cursor: pointer;
`;
const Contact = () => {
  const isMobile = useMobile();
  const goToMedia = (link) => {
    window.open(link, "_blank");
  };
  return (
    <Screen overflow={useMaxBigScreen() ? "hidden" : "visible"}>
      <OverPack id="contact" always={false}>
        <QueueAnimWithStyle key="queue" leaveReverse type="bottom">
          <Heading key="heading" textAlign="center" px={5}>
            <LightTexty>Want to hear more?</LightTexty>
            <MediumTexty>Give us a call</MediumTexty>
          </Heading>
          <ColorLine
            key="color-line"
            width="100%"
            my={4}
            marginLeft={useBigScreen() ? "calc(-50vw + 50%)" : ""}
            animation={zipLine("0", `${useBigScreen() ? "100vw" : "100%"}`)}
            animationProps="1s ease-in-out forwards"
          ></ColorLine>

          <Flex flexWrap="wrap" key="description">
            <DescriptionBox
              textAlign={isMobile ? "center" : "left"}
              pl={[0, 0, 5]}
              fontSize={4}
              width={[1, 1, 1 / 3]}
              fontFamily="text"
            >
              <Box>
                <img src={logo} width="200" height="200"></img>
              </Box>
              <Box mb={3}>
                <Box>Bulevar srpske vojske 17</Box>
                <Box>78000 Banja Luka</Box>
                <Box>Bosnia and Herzegovina</Box>
              </Box>
              <Box mb={4}>
                {<Box>
                  <LinkContact href="tel:+38751267688">
                    +387 51 267 688 <br />
                  </LinkContact>
                  <LinkContact href="tel:+38751267689">
                    +387 51 267 689
                  </LinkContact>
                </Box>}
                <Box>
                  <LinkContact href="mailto:office@antecna.com">
                    office@antecna.com
                  </LinkContact>
                </Box>
              </Box>
              <Flex justifyContent={isMobile ? "center" : ""}>
                {images.map((img, index) => (
                  <Box key={index} mr={1}>
                    <SocialImg
                      src={img.src}
                      height={[30, 30, 25]}
                      width={[30, 30, 25]}
                      onClick={() => goToMedia(img.link)}
                    ></SocialImg>
                  </Box>
                ))}
              </Flex>
            </DescriptionBox>
            <Box sx={{ position: "relative" }} width={[1, 1, 2 / 3]}>
              <ContactImage></ContactImage>
            </Box>
          </Flex>
        </QueueAnimWithStyle>
      </OverPack>
    </Screen>
  );
};

export default React.memo(Contact);
