import devices from "../common/images/software/1x/devices.png";
import elements from "../common/images/software/1x/elements.png";
import blueCircle from "../common/images/software/1x/bluecircle.png";
import redCircle1 from "../common/images/software/1x/redcircle.png";
import redCircle2 from "../common/images/software/1x/redcircle2.png";
import greenCircle from "../common/images/software/1x/greencircle.png";
import developerApps from "../common/images/software/1x/developerapps.png";
import js from "../common/images/software/1x/js.png";
import css from "../common/images/software/1x/css.png";
import html from "../common/images/software/1x/html.png";
import java from "../common/images/software/1x/java.png";

import devices2x from "../common/images/software/2x/devices@2x.png";
import elements2x from "../common/images/software/2x/elements@2x.png";
import blueCircle2x from "../common/images/software/2x/bluecircle@2x.png";
import redCircle12x from "../common/images/software/2x/redcircle@2x.png";
import redCircle22x from "../common/images/software/2x/redcircle2@2x.png";
import greenCircle2x from "../common/images/software/2x/greencircle@2x.png";
import developerApps2x from "../common/images/software/2x/developerapps@2x.png";
import js2x from "../common/images/software/2x/js@2x.png";
import css2x from "../common/images/software/2x/css@2x.png";
import html2x from "../common/images/software/2x/html@2x.png";
import java2x from "../common/images/software/2x/java@2x.png";

export default {
  devices,
  elements,
  blueCircle,
  redCircle1,
  redCircle2,
  greenCircle,
  developerApps,
  js,
  css,
  html,
  java,
  devices2x,
  elements2x,
  blueCircle2x,
  redCircle12x,
  redCircle22x,
  greenCircle2x,
  developerApps2x,
  js2x,
  css2x,
  html2x,
  java2x
};
