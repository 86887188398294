import React from "react";
import QueueAnim from "rc-queue-anim";
import styled, { keyframes, css } from "styled-components";
import { Box } from "reflexbox/styled-components";
import images from "./images";

const devicesOpacity = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1
    }
`;
const circleRotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
`;

const imgCss = css`
  height: 100%;
  width: 100%;
  ${props => props.theme.mq.md} {
    height: 90%;
    width: 90%;
  }
`;
const Image = styled.img`
  ${imgCss}
`;
const CircularImage = styled.img`
    ${imgCss}
    animation: ${circleRotate} 4s ease-in-out infinite ${props =>
  props.reverse ? "reverse" : ""};
`;

const DevicesBox = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 74px;
  top: -27px;
  animation: ${devicesOpacity} 1s ease-in-out forwards;
`;

const ElementsBox = styled(Box)`
  position: absolute;
  z-index: 20;
  top: -60px;
  right: 33px;
  ${props => props.theme.mq.md} {
    top: -57px;
    right: 30px;
  }
`;
const BlueCircleBox = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 298px;
  top: -60px;
  ${props => props.theme.mq.md} {
  }
`;
const RedCircle1Box = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 36px;
  top: -53px;
  ${props => props.theme.mq.md} {
    right: 60px;
    top: -50px;
  }
`;
const RedCircle2Box = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 294px;
  top: 67px;
  ${props => props.theme.mq.md} {
    right: 296px;
    top: 57px;
  }
`;
const GreenCircleBox = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 334px;
  top: -31px;
  ${props => props.theme.mq.md} {
    right: 329px;
    top: -32px;
  }
`;
const JSBox = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 395px;
  top: 3px;
  ${props => props.theme.mq.md} {
  }
`;
const HtmlBox = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 241px;
  top: -21px;
  ${props => props.theme.mq.md} {
    right: 250px;
    top: -23px;
  }
`;
const JavaBox = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 346px;
  top: 90px;
  ${props => props.theme.mq.md} {
    right: 347px;
    top: 79px;
  }
`;
const CssBox = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 351px;
  top: -37px;
  ${props => props.theme.mq.md} {
  }
`;
const DevelopersAppsBox = styled(Box)`
  position: absolute;
  z-index: 20;
  right: 92px;
  top: 49px;
  ${props => props.theme.mq.md} {
    right: 114px;
    top: 40px;
  }
`;
const BoxContainer = styled(Box)`
  position: relative;

  ${props => props.theme.mq.lg} {
    top: 30px;
  }
  ${props => props.theme.mq.md} {
    right: -65px;
    top: 90px;
  }
  ${props => props.theme.mq.sm} {
    position: absolute;
    top: 90px;
    right: auto;
    left: 50%;
    transform: translateX(240px);
  }
`;

const SoftwareImage = () => {
  return (
    <BoxContainer>
      <QueueAnim delay={1200} duration={650} type="bottom">
        <DevicesBox key="devices" width={300} height={184}>
          <Image src={images.devices} srcSet={images.devices2x}></Image>
        </DevicesBox>
        <ElementsBox key="elements" width={370} height={329}>
          <Image src={images.elements} srcSet={images.elements2x}></Image>
        </ElementsBox>
        <BlueCircleBox key="blue-circle" width={70} height={70}>
          <Image
            reverse={true}
            src={images.blueCircle}
            srcSet={images.blueCircle2x}
          ></Image>
        </BlueCircleBox>
        <RedCircle1Box key="red-circle1" width={120} height={120}>
          <CircularImage
            src={images.redCircle1}
            srcSet={images.redCircle12x}
          ></CircularImage>
        </RedCircle1Box>
        <RedCircle2Box key="red-circle2" width={70} height={70}>
          <Image src={images.redCircle2} srcSet={images.redCircle22x}></Image>
        </RedCircle2Box>
        <GreenCircleBox key="green-circle" width={80} height={80}>
          <CircularImage
            reverse={true}
            src={images.greenCircle}
            srcSet={images.greenCircle2x}
          ></CircularImage>
        </GreenCircleBox>
        <JSBox key="js" width={20} height={14}>
          <Image src={images.js} srcSet={images.js2x}></Image>
        </JSBox>
        <CssBox key="css" width={30} height={12}>
          <Image src={images.css} srcSet={images.css2x}></Image>
        </CssBox>
        <JavaBox key="java" width={35} height={11}>
          <Image src={images.java} srcSet={images.java2x}></Image>
        </JavaBox>
        <HtmlBox key="html" width={36} height={10}>
          <Image src={images.html} srcSet={images.html2x}></Image>
        </HtmlBox>
        <DevelopersAppsBox key="devs-box" width={75} height={34}>
          <Image
            src={images.developerApps}
            srcSet={images.developerApps2x}
          ></Image>
        </DevelopersAppsBox>
      </QueueAnim>
    </BoxContainer>
  );
};

export default SoftwareImage;
