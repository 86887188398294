import React from "react";
import OverPack from "../common/scroll/OverPackScroll";
import QueueAnim from "rc-queue-anim";
import { Flex, Box } from "reflexbox/styled-components";
import styled from "styled-components";
import Heading, { LightTexty, MediumTexty } from "../common/text/Heading";

import ColorLine, { zipLine } from "../common/color-line/ColorLine";
import Screen from "../screen/Screen";
import DescriptionBox from "../common/text/Description";
import Skills from "./Skills";
import { useBigScreen } from "../common/responsive";
import { useMobile } from "../common/responsive";
import ConsyltancyImage from "./ConslultancyImage";
import { sectionsText } from "../common/text/sectionTexts";

const Text = sectionsText.Consultancy;

const QueueAnimWithStyle = styled(QueueAnim)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const Consultancy = () => {
  return (
    <Screen>
      <OverPack id="consultancy">
        <QueueAnimWithStyle key="queue" leaveReverse type="bottom">
          <Heading
            key="heading"
            px={5}
            paddingLeft={`${useMobile() ? "0%" : "45%"}`}
            textAlign={`${useMobile() ? "center" : "left"}`}
          >
            <MediumTexty duration={225} inline>
              Consult
            </MediumTexty>
            {""}
            <LightTexty delay={225} inline>
              ancy
            </LightTexty>
          </Heading>
          <Flex key="color-line">
            <Box width={[0, 0, "45%"]}> </Box>
            <Box width={[1, 1, "55%"]}>
              <ColorLine
                key="color-line"
                my={4}
                width={1}
                animation={zipLine(
                  "0",
                  `${useBigScreen() ? "calc(100vw - 50%)" : "100%"}`
                )}
                animationProps="1s ease-in-out forwards"
              ></ColorLine>
            </Box>
          </Flex>
          <Flex flexWrap="wrap-reverse" minHeight={250} key="description">
            <Box
              px={4}
              width={[1, 1, "45%"]}
              height={[300, 300, "auto"]}
              pt={[5, 5, 0]}
              sx={{ position: "relative" }}
            >
              <ConsyltancyImage></ConsyltancyImage>
            </Box>
            <DescriptionBox
              pr={[2, 4, 5]}
              pl={[2, 4, 0]}
              width={[1, 1, "55%"]}
              fontFamily="text"
            >
              {Text}
            </DescriptionBox>
          </Flex>
          <Skills key="skills"></Skills>
        </QueueAnimWithStyle>
      </OverPack>
    </Screen>
  );
};

export default React.memo(Consultancy);
