import React from "react";
import styled, { keyframes } from "styled-components";
import { Flex } from "reflexbox/styled-components";
import QueueAnim from "rc-queue-anim";
import { Link } from "rc-scroll-anim";
import Texty from "rc-texty";
import links from "./data";

const FlexWrapper = styled(Flex)`
  position: fixed;
  top: 70px;
  left: 0;
  /* transition: all 0.2s 0.45s ease-in-out; */
  box-shadow: ${props => props.theme.shadows.small};
`;

const FlexItem = styled(Flex)`
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
  text-transform: uppercase;
`;

const StyledLink = styled(Link)`
  background: linear-gradient(
    90deg,
    ${props =>
      `${props.theme.colors.lightBlack}, ${props.theme.colors.darkBlue}`}
  );

  user-select: none;
`;
const HamburgerItems = props => {
  return (
    <FlexWrapper
      flexDirection="column"
      height={props.isOpen ? 250 : 0}
      width="100%"
    >
      <QueueAnim leaveReverse type="left">
        {props.isOpen
          ? links.map((link, index) => (
              <StyledLink
                offsetTop={90}
                onClick={props.close}
                key={index}
                to={link.to}
              >
                <FlexItem px={[5]} alignItems="center" width={1} height="50px">
                  <div onClick={link.name!="blog"?"":function(){window.open('https://blog.antecna.com')}}><Texty>{link.name}</Texty></div>
                </FlexItem>
              </StyledLink>
            ))
          : null}
      </QueueAnim>
    </FlexWrapper>
  );
};

export default HamburgerItems;
