import React from "react";
import { Box } from "reflexbox/styled-components";
import styled from "styled-components";
import { useBigScreen } from "../common/responsive";
import { useAppState } from "../context/AppContext";

const StyledBox = styled(Box)`
  position: relative;
  max-width: ${(props) => props.theme.breakpoints.xl};
  transition: all 0.3s ease-in-out;

  &::after {
    content: "";
    z-index: ${(props) => (props.isMenuOpen ? 500 : -1)};
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
      90deg,
      ${(props) =>
        `${props.theme.colors.lightBlack}, ${props.theme.colors.darkBlue}`}
    );
    opacity: ${(props) => (props.isMenuOpen ? 0.7 : 0)};
    position: absolute;
    transition: opacity 0.4s ease-in-out;
  }
`;
const Screen = (props) => {
  const { hamburgerMenuOpen } = useAppState();
  return (
    <StyledBox
      className="screen"
      isMenuOpen={hamburgerMenuOpen}
      minHeight={useBigScreen() ? "calc(70vh - 70px)" : "calc(100vh - 70px)"}
      py={[5]}
      width={1}
      overflow={props.overflow || "visible"}
      marginTop={props.marginTop}
      paddingTop={props.paddingTop ? props.paddingTop : [5]}
    >
      {props.children}
    </StyledBox>
  );
};

export default Screen;
