import breakpoints from "../common/breakpoints";

const bpsArray = Object.entries(breakpoints).reduce((arr, bp) => {
  arr.push(bp[1]);
  // eslint-disable-next-line no-param-reassign
  arr[bp[0]] = bp[1];
  return arr;
}, []);
const mediaQueries = Object.entries(breakpoints).reduce((acc, el) => {
  acc[el[0]] = `@media (max-width: ${Number.parseInt(el[1]) - 1}px)`;
  return acc;
}, {});
const theme = {
  breakpoints: bpsArray,
  mq: mediaQueries,
  colors: {
    primary: "#1890ff",
    white: "#fff",
    black: "#000",
    lightBlack: "#333133",
    darkBlue: "#001348",
    menu: "#000",
    text: "#9298a4",
    headingLight: "#c7c0c0",
    headingMediaum: "#fff",
    borders: "#e8e8e8",
    orange: "#f48b5e",
    warning: "#f5a623",
    error: "#E31C12",
    blue: "#4987ba",
    yellow: "#fcbd30",
    red: "#c04756",
    purple: "#9e4daa",
    dark: "#000"
  },
  loading: {
    minCardHeight: 100
  },
  layout: {
    max: 1258
  },
  zIndex: {
    navTabs: 10,
    nav: 120,
    navMobile: 100,
    menu: 110,
    burger: 120,
    timedLogout: 200
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
    xs: {
      paddingLeft: 15,
      paddingRight: 15
    }
  },
  containerWidths: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 970,
    xl: 1200 // 1200
  },
  space: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 128, 256],
  fonts: {
    body: `DINPro,SegoeUI, sans-serif`,
    heading: `DINPro, sans-serif`,
    text: `SegoeUI, sans-serif`,
    mono: "Menlo, monospace"
  },
  fontSizes: [11, 12, 13, 16, 18, 21, 24, 32, 48],
  letterSpacings: [1, 2, 3, 4, 5, 6],
  fontWeights: {
    body: 400,
    light: 300,
    medium: 500,
    heading: 700,
    normal: 400,
    bold: 700
  },
  shadows: {
    small: "0 2px 5px rgba(0,0,0,0.5)",
    large: "0 0 24px rgba(0, 0, 0, .125)"
  }
};

export default theme;
