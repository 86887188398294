import React from "react";
import styled from "styled-components";
import { Box } from "reflexbox/styled-components";
import Texty from "rc-texty";
import { Link } from "rc-scroll-anim";
import links from "./data";

const ItemBox = styled(Box)`
  text-transform: uppercase;
  color: white;
  user-select: none;
  position: relative;
  overflow: hidden;

  &&& {
    margin-left: 16px;
  }
  .nav-list {
    cursor: pointer;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    height: 2px;
    width: 100%;
    left: -100%;
    background: linear-gradient(
      90deg,
      rgb(64, 132, 186),
      rgb(149, 71, 155),
      rgb(190, 60, 78),
      rgb(246, 137, 88),
      rgb(250, 187, 27),
      rgb(255, 255, 255)
    );
    transition: all 0.2s linear;
  }

  &:hover::after {
    left: 0;
  }
`;

const SimpleNav = () => {
  return links.map((link, index) => (
    <ItemBox py={1} key={index} fontSize={[2, 2, 2, 3]}>
      <Link className="nav-list" to={link.to} offsetTop={90}>
          <div onClick={link.name!="blog"?"":function(){window.open('https://blog.antecna.com')}}><Texty mode="reverse">{link.name}</Texty></div>
      </Link>
    </ItemBox>
  ));
};

export default SimpleNav;
