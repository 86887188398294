import React from "react";
import { ThemeProvider } from "styled-components";
import Header from "../header/Header";
import Home from "../home/Home";
import OurStory from "../our-story/OurStory";
import Software from "../software/Software";
import Consultancy from "../consultancy/Consultancy";
import Contact from "../contact/Contact";
import GlobalStyle from "../common/global-styles/GlobalStyle";
import { theme } from "../theme";
import { AppProvider } from "../context/AppContext";
import Teams from "../teams/Teams";

window.addEventListener("beforeunload", _event => {
  window.scrollTo(0, 0);
});

const Main = () => {
  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle></GlobalStyle>
        <Header></Header>
        <Home></Home>
        <OurStory></OurStory>
        <Consultancy></Consultancy>
        <Software></Software>
        <Contact></Contact>
      </ThemeProvider>
    </AppProvider>
  );
};

export default Main;
