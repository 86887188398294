import elements1 from "../common/images/consultancy/1x/elements.png";
import elements2 from "../common/images/consultancy/1x/elements2.png";
import circle from "../common/images/consultancy/1x/circle.png";
import coffecup from "../common/images/consultancy/1x/coffecup.png";
import memorycard from "../common/images/consultancy/1x/memorycard.png";
import pliers from "../common/images/consultancy/1x/pliers.png";
import pen from "../common/images/consultancy/1x/pen.png";
import head from "../common/images/consultancy/1x/head.png";

import elements12x from "../common/images/consultancy/2x/elements@2x.png";
import elements22x from "../common/images/consultancy/2x/elements2@2x.png";
import circle2x from "../common/images/consultancy/2x/circle@2x.png";
import coffecup2x from "../common/images/consultancy/2x/coffecup@2x.png";
import memorycard2x from "../common/images/consultancy/2x/memorycard@2x.png";
import pliers2x from "../common/images/consultancy/2x/pliers@2x.png";
import pen2x from "../common/images/consultancy/2x/pen@2x.png";
import head2x from "../common/images/consultancy/2x/head@2x.png";

export default {
  elements1,
  elements2,
  circle,
  coffecup,
  memorycard,
  pliers,
  pen,
  head,
  elements12x,
  elements22x,
  circle2x,
  coffecup2x,
  memorycard2x,
  pliers2x,
  pen2x,
  head2x
};
