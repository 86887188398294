import circle1 from "../common/images/contact/1x/circle1.png";
import circle2 from "../common/images/contact/1x/circle2.png";
import circle3 from "../common/images/contact/1x/circle3.png";
import circle4 from "../common/images/contact/1x/circle4.png";
import circle5 from "../common/images/contact/1x/circle5.png";
import circle6 from "../common/images/contact/1x/circle6.png";
import neuronet from "../common/images/contact/1x/neuronet.png";

import circle12x from "../common/images/contact/2x/circle1@2x.png";
import circle22x from "../common/images/contact/2x/circle2@2x.png";
import circle32x from "../common/images/contact/2x/circle3@2x.png";
import circle42x from "../common/images/contact/2x/circle4@2x.png";
import circle52x from "../common/images/contact/2x/circle5@2x.png";
import circle62x from "../common/images/contact/2x/circle6@2x.png";
import neuronet2x from "../common/images/contact/2x/neuronet@2x.png";

export default {
  circle1,
  circle2,
  circle3,
  circle4,
  circle5,
  circle6,
  circle12x,
  circle22x,
  circle32x,
  circle42x,
  circle52x,
  circle62x,
  neuronet,
  neuronet2x
};
