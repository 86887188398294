import { keyframes } from "styled-components";

export const opacityAnim = keyframes`
from{
    opacity: 0;
}
to{
    opacity: 1
}
`;
