import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "rc-scroll-anim";
import { Flex, Box } from "reflexbox/styled-components";
import Logo from "../common/Logo";
import { useMobile } from "../common/responsive";
import HamburgerMenu from "./HamburgerMenu";
import SimpleNav from "./SimpleNav";
import { useAppState } from "../context/AppContext";

const StickyFlex = styled(Flex)`
  position: fixed;
  z-index: 1000;
  top: ${(props) => (props.top ? "0" : "30px")};
  left: 0px;
  transition: all 0.3s ease-in;
  box-shadow: ${(props) =>
    props.addShadow ? props.theme.shadows.small : "none"};
  background: linear-gradient(
    90deg,
    ${(props) =>
      `${props.theme.colors.lightBlack}, ${props.theme.colors.darkBlue}`}
  );
`;

const StyledLogo = styled(Logo)`
  cursor: pointer;
  opacity: ${(props) => (props.showed ? 1 : 0)};
  transition: all 0.4s ease-in-out;
`;

const Header = () => {
  const {
    isTopLogoShowed,
    hamburgerMenuOpen: isOpen,
    toggleHamburgerMenu: toggleMenu,
    closeHamburgerMenu: closeMenu,
  } = useAppState();
  return (
    <StickyFlex
      justifyContent="flex-start"
      color="white"
      opacity="0.95"
      height="70px"
      alignItems="center"
      width={1}
      px={useMobile() ? [5] : isTopLogoShowed ? [5] : [9]}
      addShadow={isTopLogoShowed}
      top={useMobile() ? true : isTopLogoShowed}
    >
      <Box width={1 / 3}>
        <Link className="nav-list" to="home" offsetTop={0}>
          <StyledLogo
            key="top-logo"
            showed={isOpen || isTopLogoShowed}
            width="130px"
            height="70px"
          ></StyledLogo>
        </Link>
      </Box>
      <Flex
        width={2 / 3}
        height="100%"
        alignItems="center"
        justifyContent="flex-end"
      >
        {useMobile() ? (
          <HamburgerMenu
            isOpen={isOpen}
            toggleMenu={toggleMenu}
            closeMenu={closeMenu}
          ></HamburgerMenu>
        ) : (
          <SimpleNav></SimpleNav>
        )}
      </Flex>
    </StickyFlex>
  );
};

export default Header;
