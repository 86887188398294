import React from "react";
import OverPack from "../common/scroll/OverPackScroll";
import QueueAnim from "rc-queue-anim";
import { Flex, Box } from "reflexbox/styled-components";
import styled from "styled-components";
import Heading, { MediumTexty, LightTexty } from "../common/text/Heading";

import ColorLine, { zipLine } from "../common/color-line/ColorLine";
import Screen from "../screen/Screen";
import { useBigScreen } from "../common/responsive";
import { useMobile } from "../common/responsive";
import Members from "./Members";

const QueueAnimWithStyle = styled(QueueAnim)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const Teams = () => {
  return (
    <Screen>
      <OverPack id="team">
        <QueueAnimWithStyle key="queue" type="bottom">
          <Heading
            key="heading"
            px={5}
            paddingLeft={`${useMobile() ? "32px" : "45%"}`}
            textAlign={`${useMobile() ? "center" : "left"}`}
          >
            <LightTexty duration={225} inline>
              The
            </LightTexty>{" "}
            <MediumTexty delay={225} inline>
              Team
            </MediumTexty>
          </Heading>
          <Flex key="color-line">
            <Box width={[0, 0, "45%"]}> </Box>
            <Box width={[1, 1, "55%"]}>
              <ColorLine
                key="color-line"
                my={4}
                width={1}
                animation={zipLine(
                  "0",
                  `${useBigScreen() ? "calc(100vw - 50%)" : "100%"}`
                )}
                animationProps="1s ease-in-out forwards"
              ></ColorLine>
            </Box>
          </Flex>
          <Members key="members"></Members>
        </QueueAnimWithStyle>
      </OverPack>
    </Screen>
  );
};

export default React.memo(Teams);
