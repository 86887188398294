import milosZ from "../common/images/teams/members/milosz.jpg";
import markoM from "../common/images/teams/members/Malina.jpg";
import aleksandarB from "../common/images/teams/members/buzak.jpg";
import aleksandarA from "../common/images/teams/members/Sale.jpg";
import krstivojP from "../common/images/teams/members/kico.jpg";
import dejanG from "../common/images/teams/members/gaja.jpg";
import duskoD from "../common/images/teams/members/dusko.jpg";
import djordjeT from "../common/images/teams/members/dzoks.jpg";
import jovanD from "../common/images/teams/members/jovan.jpg";
import jovicaK from "../common/images/teams/members/jovica.jpg";
import dragisaS from "../common/images/teams/members/dragisa.jpg";
import borislavK from "../common/images/teams/members/kokeza.jpg";
import ognjenK from "../common/images/teams/members/ogi.jpg";
import vanjaK from "../common/images/teams/members/vanja.jpg";
import slavenZ from "../common/images/teams/members/slaven.jpg";
import nemanjaK from "../common/images/teams/members/nemanja.jpg";
import dejanV from "../common/images/teams/members/dejo.jpg";
import danijelM from "../common/images/teams/members/dado.jpg";
import markoK from "../common/images/teams/members/marko.jpg";
import milosG from "../common/images/teams/members/gala.jpg";
import vladoC from "../common/images/teams/members/vlado.jpg";
import mikiV from "../common/images/teams/members/miki.jpg";
import drazenkoS from "../common/images/teams/members/drazenko.jpg";
import milicaMA from "../common/images/teams/members/milica.JPG";
import cedomirS from "../common/images/teams/members/cedomir.jpg";
import stefanR from "../common/images/teams/members/stefanR.jpg";

export default {
  milosZ,
  markoM,
  aleksandarB,
  aleksandarA,
  krstivojP,
  dejanG,
  duskoD,
  djordjeT,
  jovanD,
  jovicaK,
  dragisaS,
  borislavK,
  ognjenK,
  vanjaK,
  nemanjaK,
  dejanV,
  danijelM,
  markoK,
  milosG,
  mikiV,
  vladoC,
  slavenZ,
  drazenkoS,
  milicaMA,
  cedomirS,
  stefanR
};
