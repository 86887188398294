import React from "react";
import { Flex, Box } from "reflexbox/styled-components";
import styled from "styled-components";
import TextyAnim from "rc-texty";
import QueueAnim from "rc-queue-anim";
import { useMobile, useRetina } from "../common/responsive";
import data from "./teams.data";
import ring from "../common/images/teams/1x/ring.png";
import ring2x from "../common/images/teams/2x/ring@2x.png";
import bckImg from "../common/images/teams/1x/bck.png";
import bck2xImg from "../common/images/teams/2x/bck@2x.png";
import lnImg from "../common/images/social-media/in.svg";

const MemberImgWraper = styled(Box)`
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.img});
  height: 180px;
  width: 180px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  &::before {
    content: "";

    background: black;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    transition: all 0.3s ease-in-out;
    transform: translateY(100%);
    background-position: center;
    background-size: 40px;
    background-repeat: no-repeat;
    background-image: url(${lnImg});
  }
  &:hover::before {
    transform: translateY(0%);
  }
`;

const MemberImg = styled(Box)`
  background-image: url(${(props) => props.img});
  background-size: cover;
  height: calc(100% - 6px);
  width: calc(100% - 6px);
  border-radius: 1000px;
  background-position: ${(props) => props.position};
  margin-left: 3px;
  margin-top: 3px;
`;

const Member = (props) => {
  const goToLn = (link) => {
    window.open(link, "_blank");
  };
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      color="white"
      flexDirection="column"
      fontWeight="light"
      mb={4}
      mx={5}
    >
      <MemberImgWraper
        onClick={() => goToLn(props.link)}
        img={useRetina() ? ring2x : ring}
        mb={2}
      >
        <MemberImg img={props.img} position={props.position}></MemberImg>
      </MemberImgWraper>
      <Box fontSize={2} fontWeight="bold">
        {props.name}
      </Box>
      <Box fontSize={1} sx={{ opacity: 0.7 }}>
        {props.role}
      </Box>
    </Flex>
  );
};

const MembersWrapper = styled(Flex)``;
const MembersBackground = styled(Flex)`
  background-image: url(${(props) => props.img});
  background-size: contain;
  mask-image: linear-gradient(
    rgb(0, 0, 0) ${(props) => props.heightPercentage}%,
    transparent
  );
  padding-bottom: 5%;
`;
const Members = (props) => {
  return (
    <MembersWrapper key={props.key} m={5}>
      <MembersBackground
        img={useRetina() ? bck2xImg : bckImg}
        heightPercentage={useMobile() ? 98 : 95}
        width={1}
        justifyContent={[
          "center",
          "space-around",
          "space-around",
          "space-between",
        ]}
        alignItems="center"
        flexWrap="wrap"
      >
        {data.map((item, index) => (
          <Member key={index} {...item}></Member>
        ))}
        <Box mb={4} mx={5} width={180}></Box>
        <Box mb={4} mx={5} width={180}></Box>
      </MembersBackground>
    </MembersWrapper>
  );
};

export default Members;
