import laptop from "../common/images/home/1x/laptop.png";
import phone from "../common/images/home/1x/phone.png";
import tablet from "../common/images/home/1x/tablet.png";
import part1 from "../common/images/home/1x/graphic1.png";
import part2 from "../common/images/home/1x/graphic2.png";
import part3 from "../common/images/home/1x/graphic3.png";
import part4 from "../common/images/home/1x/graphic4.png";

import laptop2x from "../common/images/home/2x/laptop@2x.png";
import phone2x from "../common/images/home/2x/phone@2x.png";
import tablet2x from "../common/images/home/2x/tablet@2x.png";
import part12x from "../common/images/home/2x/graphic1@2x.png";
import part22x from "../common/images/home/2x/graphic2@2x.png";
import part32x from "../common/images/home/2x/graphic3@2x.png";
import part42x from "../common/images/home/2x/graphic4@2x.png";

export default {
  laptop,
  phone,
  tablet,
  part1,
  part2,
  part3,
  part4,
  laptop2x,
  phone2x,
  tablet2x,
  part12x,
  part22x,
  part32x,
  part42x
};
