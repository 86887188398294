import React, { useEffect, useRef } from "react";
import QueueAnim from "rc-queue-anim";
import { size, space, layout } from "styled-system";
import { Flex } from "reflexbox/styled-components";
import Texty from "rc-texty";
import styled, { keyframes } from "styled-components";
import logo from "../common/images/antecna-a-logo.svg";
import Heading from "../common/text/Heading";
import Screen from "../screen/Screen";
import ColorLine, { zipLine } from "../common/color-line/ColorLine";
import HomeImage from "./HomeImage";
import { useAppState } from "../context/AppContext";
import { opacityAnim } from "../common/anims/anims";
import { useMobile, useSmallScreen } from "../common/responsive";

const LogoImg = styled.img`
  transition: all 0.4s ease-in-out;
  opacity: ${(props) => (props.showed ? 1 : 0)};
  width: 200px;
  position: relative;
  ${(props) => props.theme.mq.sm} {
    top: 0px;
    width: 170px;
    left: 50%;
    margin-left: -85px;
    position: aboslute;
  }
  animation: ${opacityAnim} 1s ease-in-out;
`;
const QueueAnimWithStyle = styled(QueueAnim)`
  height: 100vh;
  width: 100%;
  ${size}
  ${space}
  ${layout}
  display: flex;
  justify-content: center;
  flex-direction: column;
    ${(props) => props.theme.mq.sm} {
      position: relative;
      display: block
    }
 
`;
// const zipLine = keyframes`
//   from {
//     width: 0;
//   }

//   to {
//     width: calc(100% + 45px);
//   }
// `;
const StyledHeading = styled(Heading)`
  position: relative;
  top: 75px;
  z-index: 30;
  ${(props) => props.theme.mq.lgl} {
    top: ${(props) => props.theme.space[4]}px;
  }

  ${(props) => props.theme.mq.xs} {
    top: ${(props) => props.theme.space[8]}px;
  }

  ${(props) => props.theme.mq.sm} {
    position: absolute;
    top: 70vh;
    width: 100%;
    left: 0;
  }
`;
const LightTexty = styled(Texty)`
  font-weight: ${(props) => props.theme.fontWeights.light};
  color: ${(props) => props.theme.colors.headingLight};
`;
const MediumTexty = styled(Texty)`
  font-weight: ${(props) => props.theme.fontWeights.medium};
  color: ${(props) => props.theme.colors.headingMedium};
`;
const Home = () => {
  const { showTopMenu, hideTopMenu, isTopLogoShowed } = useAppState();
  const logoEl = useRef();
  const handleScroll = () => {
    if (logoEl.current) {
      const imgTop = logoEl.current.getBoundingClientRect().top;
      if (window.innerWidth < 760) {
        window.scrollY + 70 >= imgTop + window.pageYOffset
          ? showTopMenu()
          : hideTopMenu();
      } else window.scrollY > 130 ? showTopMenu() : hideTopMenu();
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Screen>
      <QueueAnimWithStyle
        id="home"
        px={[5, 5, 9]}
        pt={9}
        delay={200}
        type="left"
      >
        <Flex key="aa" width={1} flexDirection="column">
          <LogoImg
            ref={logoEl}
            showed={!isTopLogoShowed}
            alt=""
            src={logo}
          ></LogoImg>
          <ColorLine
            top="-35px"
            opacity={!isTopLogoShowed | useMobile() ? "1" : "0"}
            left={useMobile() ? "-35px" : "60px"}
            top={useMobile() ? "calc(73vh - 100px);" : ""}
            marginTop={useMobile() ? "0" : "-50px"}
            position={useMobile() ? "absolute" : "relative"}
            animation={zipLine(0, "103vw")}
            animationProps="1s ease-in-out forwards"
          ></ColorLine>
        </Flex>
        <HomeImage></HomeImage>
        <StyledHeading
          key="a"
          width={1}
          textAlign={`${useMobile() ? "center" : "right"}`}
        >
          <MediumTexty delay={300}>software.</MediumTexty>
          <LightTexty delay={850}>demystified.</LightTexty>
        </StyledHeading>
      </QueueAnimWithStyle>
    </Screen>
  );
};

export default React.memo(Home);
