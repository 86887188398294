import React from "react";
import QueueAnim from "rc-queue-anim";
import styled, { keyframes } from "styled-components";
import { Box } from "reflexbox/styled-components";
import images from "./images";

const fireOpacity = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1
    }
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  ${props => props.theme.mq.md} {
    height: 80%;
    width: 80%;
  }
`;
const HeroBox = styled(Box)`
  position: absolute;
  right: 110px;
  top: -333px;
  z-index: 20;
  ${props => props.theme.mq.md} {
    right: 120px;
    top: -295px;
  }
`;
const FireBox = styled(Box)`
  position: absolute;
  right: 90px;
  top: -140px;
  animation: ${fireOpacity} 1s ease-in-out forwards;
  ${props => props.theme.mq.md} {
    right: 90px;
    top: -140px;
  }
`;

const NeuroNetBox = styled(Box)`
  position: absolute;
  right: 40px;
  top: -344px;
  z-index: 25;
  ${props => props.theme.mq.md} {
    right: 40px;
    top: -325px;
  }
`;

const BoxContainer = styled(Box)`
  position: relative;
  ${props => props.theme.mq.md} {
    right: -60px;
    bottom: -31px;
  }
  ${props => props.theme.mq.sm} {
    height: 278px;
    bottom: -310px;
  }
  ${props => props.theme.mq.xs} {
    height: 288px;
    bottom: -320px;
  }
`;

const OurStoryImage = () => {
  return (
    <BoxContainer>
      <QueueAnim delay={1200} duration={400} type="bottom">
        <FireBox key="fire" width="300px" height="169px">
          <Image src={images.fire} srcSet={images.fire2x}></Image>
        </FireBox>
        <HeroBox key="hero" width="230px" height="339px">
          <Image src={images.hero} srcSet={images.hero2x}></Image>
        </HeroBox>
      </QueueAnim>
      <QueueAnim delay={1650} duration={300} type="right">
        <NeuroNetBox key="neuronet" width="300px" height="280px">
          <Image src={images.neuronet} srcSet={images.neuronet2x}></Image>
        </NeuroNetBox>
      </QueueAnim>
    </BoxContainer>
  );
};

export default OurStoryImage;
